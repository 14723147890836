import { default as aboutaC0FMxDxqnMeta } from "/src/pages/about.vue?macro=true";
import { default as errorIIKsQeD5CQMeta } from "/src/pages/error.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as _91id_93TxFophCVKxMeta } from "/src/pages/pay/doku/[id].vue?macro=true";
import { default as _91id_93xklMQZPnHdMeta } from "/src/pages/pay/method/[id].vue?macro=true";
import { default as _91id_93Gcti7PWypKMeta } from "/src/pages/pay/midtrans/[id].vue?macro=true";
import { default as _91id_93adpVwLvwqvMeta } from "/src/pages/pay/neobank/[id].vue?macro=true";
import { default as _91id_93liec7EcumAMeta } from "/src/pages/pay/speedcash/[id].vue?macro=true";
import { default as _91id_93Du74QHbIjgMeta } from "/src/pages/pay/xendit/[id].vue?macro=true";
import { default as payment_45successar5FeCuNp7Meta } from "/src/pages/payment-success.vue?macro=true";
import { default as _91id_93F6lDUxVuRHMeta } from "/src/pages/posts/[id].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/src/pages/about.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/src/pages/error.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "pay-doku-id",
    path: "/pay/doku/:id()",
    component: () => import("/src/pages/pay/doku/[id].vue")
  },
  {
    name: "pay-method-id",
    path: "/pay/method/:id()",
    component: () => import("/src/pages/pay/method/[id].vue")
  },
  {
    name: "pay-midtrans-id",
    path: "/pay/midtrans/:id()",
    component: () => import("/src/pages/pay/midtrans/[id].vue")
  },
  {
    name: "pay-neobank-id",
    path: "/pay/neobank/:id()",
    component: () => import("/src/pages/pay/neobank/[id].vue")
  },
  {
    name: "pay-speedcash-id",
    path: "/pay/speedcash/:id()",
    component: () => import("/src/pages/pay/speedcash/[id].vue")
  },
  {
    name: "pay-xendit-id",
    path: "/pay/xendit/:id()",
    component: () => import("/src/pages/pay/xendit/[id].vue")
  },
  {
    name: "payment-success",
    path: "/payment-success",
    component: () => import("/src/pages/payment-success.vue")
  },
  {
    name: "posts-id",
    path: "/posts/:id()",
    component: () => import("/src/pages/posts/[id].vue")
  }
]